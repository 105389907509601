import React, { useEffect } from "react";
import styled from "styled-components";
import {
  darkPurple,
  subFont,
  mainWhite,
  transHover,
  mainPurple,
  screen,
} from "../variables";
import { GatsbyImage } from "gatsby-plugin-image";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { FaLinkedinIn, FaFacebookF } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import { ScrollLocky } from "react-scroll-locky";
import BrandArrow from "../../images/svg/brand-arrow.svg";

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const item = {
  hidden: { opacity: 0, x: -15 },
  show: { opacity: 1, x: 0 },
};

const Wrapper = styled(motion.div)`
  background: rgba(46, 41, 78, 0.98);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 21;
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    background: ${mainWhite};
    border-radius: 16px;
    max-width: 1200px;
    width: calc(100% - 50px);
    position: relative;
    padding: 25px 25px 30px 25px;
    margin: 0 25px;
    @media ${screen.xsmall} {
      display: flex;
      padding: 60px;
      border-radius: 20px;
    }

    @media ${screen.small} {
      padding: 80px;
      border-radius: 25px;
    }

    @media ${screen.medium} {
      padding: 60px 120px 60px 120px;
      border-radius: 30px;
    }

    .col {
      &--left {
        position: relative;
        @media ${screen.xsmall} {
          min-width: 250px;
        }

        .img {
          max-width: 120px;
          overflow: hidden;
          @media ${screen.xsmall} {
            max-width: 180px;
          }
          img {
            border-radius: 15px;
          }
        }

        .social-list {
          display: flex;
          align-items: center;
          margin: 7px 0 0 0;
          @media ${screen.xsmall} {
            margin: 20px 0 0 0;
          }

          &__each {
            a {
              color: ${darkPurple};
              font-size: 1.35rem;
              display: block;
              margin: 0 10px 0 0;
              @media ${screen.xsmall} {
                font-size: 1.8rem;
              }
              @media ${screen.withCursor} {
                &:hover {
                  color: ${mainPurple};
                }
              }
            }
          }
        }

        .brand-arrow {
          display: none;
          @media ${screen.xsmall} {
            display: block;
            position: absolute;
            left: -48px;
            top: 0;
            width: 27px;
          }
        }
      }

      &--right {
        .description {
          margin: 12px 0 0 0;
          @media ${screen.xsmall} {
            margin: 20px 0 0 0;
          }

          p {
            font-size: 0.95rem;
            margin: 0 0 21px 0;
            @media ${screen.xsmall} {
              font-size: 1.15rem;
            }
            @media ${screen.small} {
              font-size: 1.2rem;
            }
            @media ${screen.medium} {
              font-size: 1.25rem;
            }

            &:last-child {
              margin: 0;
            }
          }

          ul,
          ol {
            padding: 0 0 0 50px;
            li {
              font-size: 1.25rem;
              margin: 0 0 21px 0;
              line-height: 1.4;
            }
          }

          ul {
            li {
              list-style-type: disc;
            }
          }

          ol {
            li {
              list-style-type: decimal;
            }
          }
        }
      }

      .info {
        .name {
          font-family: ${subFont};
          font-size: 1.3rem;
          @media ${screen.xsmall} {
            font-size: 1.8rem;
          }
          @media ${screen.small} {
            font-size: 1.9rem;
          }
          @media ${screen.medium} {
            font-size: 2rem;
          }
        }

        .position {
          color: ${mainPurple};
          font-family: ${subFont};
          font-size: 1.3rem;
          @media ${screen.xsmall} {
            font-size: 1.8rem;
          }
          @media ${screen.small} {
            font-size: 1.9rem;
          }
          @media ${screen.medium} {
            font-size: 2rem;
          }
        }

        &--mobile {
          margin: 20px 0 0 0;
          @media ${screen.xsmall} {
            display: none;
          }
        }

        &--desktop {
          display: none;
          @media ${screen.xsmall} {
            display: block;
          }
        }
      }
    }

    .close-btn {
      font-size: 1.96rem;
      position: absolute;
      right: 17px;
      top: 14px;
      cursor: pointer;
      transition: ${transHover};
      @media ${screen.xsmall} {
        font-size: 2.16rem;
        right: 25px;
        top: 25px;
      }
      @media ${screen.withCursor} {
        &:hover {
          color: ${mainPurple};
        }
      }
    }
  }
`;

const LightboxTeamMember = ({ member, setActiveMember }) => {
  const { name, position, image, description, linkedInUrl, facebookUrl } =
    member;

  const isActive = member.name || false;

  useEffect(() => {
    const userClick = (e) => {
      if (e.target.id === "member-lightbox") setActiveMember({});
    };

    window.addEventListener("click", userClick);

    return () => {
      window.removeEventListener("click", userClick);
    };
  }, []);

  return (
    <AnimatePresence>
      {isActive && (
        <Wrapper
          id="member-lightbox"
          initial="hidden"
          animate={isActive ? "show" : "hidden"}
          variants={container}
          exit={{ opacity: 0 }}
        >
          <ScrollLocky isolation={false}>
            <div className="container">
              <div className="col col--left">
                <div className="brand-arrow" role="presentation">
                  <BrandArrow />
                </div>
                <motion.figure variants={item} className="img">
                  <GatsbyImage image={image} alt={`${name}'s Portrait'`} />
                </motion.figure>
                <motion.div className="info info--mobile" variants={item}>
                  <h3 className="name">{name}</h3>
                  <p className="position">{position}</p>
                </motion.div>
                <motion.ul variants={item} className="social-list">
                  {linkedInUrl && (
                    <li className="social-list__each">
                      <a
                        href={linkedInUrl}
                        rel="noopener"
                        aria-label="Visit social platform"
                        target="_blank"
                      >
                        <FaLinkedinIn />
                      </a>
                    </li>
                  )}

                  {facebookUrl && (
                    <li className="social-list__each">
                      <a
                        href={facebookUrl}
                        rel="noopener"
                        aria-label="Visit social platform"
                        target="_blank"
                      >
                        <FaFacebookF />
                      </a>
                    </li>
                  )}
                </motion.ul>
              </div>
              <motion.div variants={item} className="col col--right">
                <motion.div className="info info--desktop" variants={item}>
                  <h3 className="name">{name}</h3>
                  <p className="position">{position}</p>
                </motion.div>
                <div
                  className="description"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </motion.div>

              <p
                className="close-btn"
                role="button"
                onClick={() => {
                  setActiveMember({});
                }}
              >
                <IoIosCloseCircleOutline />
              </p>
            </div>
          </ScrollLocky>
        </Wrapper>
      )}
    </AnimatePresence>
  );
};

export default LightboxTeamMember;
