import React, { useState } from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import styled from "styled-components";
import { graphql } from "gatsby";
import {
  darkPurple,
  innerWidth,
  mainPurple,
  mainWhite,
  screen,
  subFont,
  transHover,
  moveBgColor,
} from "../components/variables";
import BrandArrow from "../images/svg/brand-arrow.svg";
import LightboxTeamMember from "../components/lightbox-team-member";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import CarouselTestimonials from "../components/carousel/testimonials";
import Obfuscate from "react-obfuscate";

const Wrapper = styled.div`
  padding: 60px 0 0 0;
  @media ${screen.xsmall} {
    padding: 100px 0 0 0;
  }

  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 0 25px;
    @media ${screen.xsmall} {
      padding: 0 40px;
    }
    @media ${screen.medium} {
      padding: 0;
    }

    &--intro {
      @media ${screen.xsmall} {
        display: flex;
      }
    }

    &--testimonials {
      @media ${screen.xsmall} {
        display: flex;
      }
    }
  }

  .hero {
    overflow: hidden;
    position: relative;
    @media ${screen.xsmall} {
      background-image: ${(props) => `url(${props.banner_background_image})`};
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      align-items: center;
      min-height: 610px;
    }

    .gradient-bg {
      position: absolute;
      background-image: linear-gradient(120deg, #67b6ff, #3697ed, #6300ff);
      background-size: 340%;
      animation: ${moveBgColor} 5s infinite alternate;
      width: 100%;
      height: 100%;
      @media ${screen.xsmall} {
        position: absolute;
        width: 80%;

        height: 180%;
        left: -22%;
        top: 0;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .text-wrapper {
      padding: 50px 25px 50px 25px;
      position: relative;
      z-index: 3;
      @media ${screen.xsmall} {
        width: 60%;
        padding: 0 80px 0 0;
      }

      .text-subwrapper {
        max-width: 600px;
        margin: 0 0 0 auto;
        @media ${screen.xsmall} {
          padding: 0 50px 0 40px;
        }
        @media ${screen.medium} {
          padding: 0 50px 0 0;
        }

        .description {
          margin: 22px 0 32px 0;
          position: relative;
          z-index: 2;
          color: ${mainWhite};

          h1 {
            font-family: ${subFont};
            font-size: 2.38rem;
            color: ${darkPurple};
            line-height: 1.05;
            margin: 0 0 6px 0;
            @media ${screen.xsmall} {
              font-size: 3.7rem;
            }
            @media ${screen.small} {
              font-size: 3.9rem;
            }
            @media ${screen.medium} {
              font-size: 4.15rem;
            }

            strong {
              color: ${mainWhite};
              display: block;
              margin: 0 0 0 52px;
            }
          }

          p {
            font-size: 1.05rem;
            line-height: 1.4;
            margin: 0 0 21px 0;
            @media ${screen.xsmall} {
              font-size: 1.3rem;
            }
            @media ${screen.small} {
              font-size: 1.4rem;
            }
            @media ${screen.medium} {
              font-size: 1.5rem;
            }
            &:last-child {
              margin: 0;
            }
          }
        }
      }
    }

    .arrows-bg {
      position: absolute;
      z-index: 2;
      top: calc(50% + 60px);
      transform: translateY(-50%);
      left: -120px;
      pointer-events: none;
      width: 500px;
      @media ${screen.xsmall} {
        width: 950px;
        left: -400px;
        top: 50%;
      }
      @media ${screen.small} {
        width: 900px;
        left: -250px;
      }
      @media ${screen.medium} {
        width: 1000px;
      }
      @media ${screen.large} {
        width: calc(750px + 12vw);
        left: -70px;
      }
    }
  }

  .intro {
    padding: 55px 0;
    @media ${screen.xsmall} {
      padding: 80px 0;
    }
    @media ${screen.medium} {
      padding: 90px 0;
    }

    .col {
      &--left {
        @media ${screen.xsmall} {
          padding: 0 40px 0 0;
          width: 55%;
        }
        @media ${screen.small} {
          padding: 0 60px 0 0;
        }
        @media ${screen.medium} {
          padding: 0 75px 0 0;
        }
      }

      &--right {
        margin: 25px 0 0 0;
        @media ${screen.xsmall} {
          width: 45%;
          margin: 0;
        }

        .description {
          h2 {
            font-family: ${subFont};
            font-size: 1.55rem;
            margin: 0 0 20px 0;
            @media ${screen.xsmall} {
              font-size: 1.8rem;
            }
            @media ${screen.small} {
              font-size: 1.9rem;
            }
            @media ${screen.medium} {
              font-size: 2rem;
            }

            strong {
              color: ${mainPurple};
              font-style: normal;
            }
          }

          p {
            font-size: 1.05rem;
            margin: 0 0 21px 0;
            @media ${screen.xsmall} {
              font-size: 1.15rem;
            }
            @media ${screen.small} {
              font-size: 1.2rem;
            }
            @media ${screen.medium} {
              font-size: 1.25rem;
            }
            &:last-child {
              margin: 0;
            }
          }

          ul,
          ol {
            padding: 0 0 0 40px;
            @media ${screen.xsmall} {
              padding: 0 0 0 50px;
            }

            li {
              font-size: 1.05rem;
              margin: 0 0 21px 0;
              line-height: 1.4;
              @media ${screen.xsmall} {
                font-size: 1.15rem;
              }
              @media ${screen.small} {
                font-size: 1.2rem;
              }
              @media ${screen.medium} {
                font-size: 1.25rem;
              }
            }
          }

          ul {
            li {
              list-style-type: disc;
            }
          }

          ol {
            li {
              list-style-type: decimal;
            }
          }
        }
      }
    }
  }

  .team {
    background-image: linear-gradient(-120deg, #67b6ff, #3697ed, #6300ff);
    background-size: 600%;
    animation: ${moveBgColor} 5s infinite alternate;
    color: ${mainWhite};
    padding: 55px 0 40px 0;
    position: relative;
    overflow: hidden;
    @media ${screen.xsmall} {
      padding: 80px 0 60px 0;
    }
    @media ${screen.medium} {
      padding: 90px 0 60px 0;
    }

    .arrows-bg {
      position: absolute;
      z-index: 2;
      top: 40px;
      left: -25px;
      pointer-events: none;
      width: 650px;
      @media ${screen.xsmall} {
        width: 1000px;
        left: -150px;
        top: 100px;
      }
      @media ${screen.small} {
        width: 1190px;
      }
      @media ${screen.medium} {
        width: 1270px;
      }
      @media ${screen.large} {
        width: calc(1300px + 12vw);
        left: -70px;
      }
    }

    .description {
      max-width: 600px;
      position: relative;
      z-index: 3;

      h2 {
        font-family: ${subFont};
        font-size: 1.55rem;
        color: ${darkPurple};
        line-height: 1.16;
        margin: 0 0 20px 0;
        @media ${screen.xsmall} {
          font-size: 2.52rem;
        }
        @media ${screen.small} {
          font-size: 2.62rem;
        }
        @media ${screen.medium} {
          font-size: 2.72rem;
        }

        strong {
          color: ${mainWhite};
          font-style: normal;
          display: block;
          margin: 0 0 0 50px;
          @media ${screen.xsmall} {
            margin: 0 0 0 130px;
          }
        }
      }

      p {
        font-size: 1.05rem;
        margin: 0 0 21px 0;
        @media ${screen.xsmall} {
          font-size: 1.15rem;
        }
        @media ${screen.small} {
          font-size: 1.2rem;
        }
        @media ${screen.medium} {
          font-size: 1.25rem;
        }

        &:last-child {
          margin: 0;
        }
      }
    }

    .team-list {
      display: flex;
      flex-wrap: wrap;
      margin: 32px -15px 0 -15px;
      position: relative;
      z-index: 3;
      @media ${screen.xsmall} {
        margin: 40px -40px 0 -40px;
      }
      @media ${screen.small} {
        margin: 40px -30px 0 -30px;
      }
      @media ${screen.medium} {
        margin: 40px -40px 0 -40px;
      }
      @media ${screen.large} {
        margin: 40px -50px 0 -50px;
      }

      &__each {
        width: calc(50% - 30px);
        margin: 0 15px 30px 15px;
        cursor: pointer;
        @media ${screen.xsmall} {
          width: calc(33.33% - 80px);
          margin: 0 40px 75px 40px;
        }
        @media ${screen.small} {
          width: calc(25% - 60px);
          margin: 0 30px 75px 30px;
        }

        @media ${screen.medium} {
          width: calc(25% - 80px);
          margin: 0 40px 75px 40px;
        }

        @media ${screen.large} {
          width: calc(25% - 100px);
          margin: 0 50px 75px 50px;
        }

        .img {
          overflow: hidden;
          position: relative;

          img {
            border-radius: 10px;
            @media ${screen.xsmall} {
              border-radius: 15px;
            }
          }
        }

        .name {
          font-size: 1rem;
          font-weight: 700;
          margin: 10px 0 0 0;
          line-height: 1.35;
          @media ${screen.xsmall} {
            font-size: 1.15rem;
            margin: 15px 0 0 0;
          }
          @media ${screen.small} {
            font-size: 1.2rem;
            margin: 20px 0 0 0;
          }
          @media ${screen.medium} {
            font-size: 1.25rem;
          }
        }

        .position {
          font-size: 1rem;
          margin: 4px 0 0 0;
          @media ${screen.xsmall} {
            font-size: 1.15rem;
            margin: 7px 0 0 0;
          }
          @media ${screen.small} {
            font-size: 1.2rem;
          }
          @media ${screen.medium} {
            font-size: 1.25rem;
          }
        }

        .overlay {
          background: rgba(255, 255, 255, 0.9);
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          border-radius: 10px;
          display: flex;
          opacity: 0;
          transition: ${transHover};
          @media ${screen.xsmall} {
            border-radius: 15px;
          }

          p {
            color: ${darkPurple};
            margin: auto;
            font-weight: 700;
            display: flex;
            align-items: center;
            @media ${screen.xsmall} {
              font-size: 1.15rem;
            }
            @media ${screen.small} {
              font-size: 1.2rem;
            }
            @media ${screen.medium} {
              font-size: 1.25rem;
            }

            svg {
              width: 18px;
              margin: 0 0 -3px 6px;
            }
          }
        }

        @media ${screen.withCursor} {
          &:hover .overlay {
            opacity: 1;
          }
        }

        &--no-bio {
          pointer-events: none;
        }
      }
    }
  }

  .testimonials {
    padding: 55px 0 34px 0;
    @media ${screen.xsmall} {
      padding: 80px 0;
    }
    @media ${screen.medium} {
      padding: 90px 0;
    }

    .col {
      &--left {
        @media ${screen.xsmall} {
          width: 50%;
        }
        .description {
          margin: 0 0 16px 0;
          max-width: 600px;
          @media ${screen.xsmall} {
            margin: 0 0 32px 0;
          }

          h2,
          h3 {
            font-family: ${subFont};
            font-size: 1.55rem;
            color: ${darkPurple};
            line-height: 1.16;
            margin: 0 0 20px 0;
            @media ${screen.xsmall} {
              font-size: 2.25rem;
            }
            @media ${screen.small} {
              font-size: 2.35rem;
            }
            @media ${screen.medium} {
              font-size: 2.45rem;
            }

            strong {
              color: ${mainPurple};
            }
          }

          p {
            font-size: 1.05rem;
            margin: 0 0 21px 0;
            @media ${screen.xsmall} {
              font-size: 1.15rem;
            }
            @media ${screen.small} {
              font-size: 1.2rem;
            }
            @media ${screen.medium} {
              font-size: 1.25rem;
            }
            &:last-child {
              margin: 0;
            }
          }
        }

        .slides {
          max-width: 600px;
        }
      }

      &--right {
        margin: 120px 0 0 0;
        @media ${screen.xsmall} {
          width: 50%;
          padding: 0 0 0 100px;
          margin: 0;
        }

        .aside-list {
          &__each {
            margin: 0 0 40px 0;

            .description {
              h1,
              h2,
              h3,
              h4,
              h5,
              h6 {
                font-weight: 700;
                font-family: ${subFont};
                margin: 0 0 15px 0;
                color: ${mainPurple};
              }

              h1 {
                font-size: 1.65rem;
                @media ${screen.xsmall} {
                  font-size: 1.8rem;
                }
              }
              h2 {
                font-size: 1.55rem;
                @media ${screen.xsmall} {
                  font-size: 1.7rem;
                }
              }
              h3 {
                font-size: 1.45rem;
                @media ${screen.xsmall} {
                  font-size: 1.6rem;
                }
              }
              h4 {
                font-size: 1.35rem;
                @media ${screen.xsmall} {
                  font-size: 1.5rem;
                }
              }
              h5 {
                font-size: 1.25rem;
                @media ${screen.xsmall} {
                  font-size: 1.4rem;
                }
              }
              h6 {
                font-size: 1.2rem;
                @media ${screen.xsmall} {
                  font-size: 1.3rem;
                }
              }

              p {
                font-size: 0.95rem;
                margin: 0 0 19px 0;
                @media ${screen.xsmall} {
                  font-size: 1.1rem;
                }

                &:last-child {
                  margin: 0;
                }
              }

              a {
                color: ${darkPurple};
                text-decoration: underline;
                @media ${screen.withCursor} {
                  &:hover {
                    color: ${mainPurple};
                  }
                }
              }

              ul,
              ol {
                padding: 0 0 0 40px;
                @media ${screen.xsmall} {
                  padding: 0 0 0 50px;
                }
                li {
                  font-size: 0.95rem;
                  margin: 0 0 19px 0;
                  line-height: 1.4;
                  @media ${screen.xsmall} {
                    font-size: 1.1rem;
                  }
                }
              }

              ul {
                li {
                  list-style-type: disc;
                }
              }

              ol {
                li {
                  list-style-type: decimal;
                }
              }
            }
          }
        }
      }
    }
  }

  .join {
    padding: 55px 0;
    background-image: ${(props) => `url(${props.join_background_image})`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    @media ${screen.xsmall} {
      padding: 100px 0;
    }
    @media ${screen.small} {
      padding: 110px 0;
    }

    &::after {
      content: "";
      background: rgba(46, 41, 78, 0.5);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
    }

    .box {
      background: rgba(255, 255, 255, 0.8);
      max-width: 500px;
      padding: 30px 30px 35px 30px;
      border-radius: 15px;
      position: relative;
      z-index: 2;
      margin: 0 0 0 auto;
      @media ${screen.xsmall} {
        padding: 40px;
        border-radius: 24px;
      }
      @media ${screen.small} {
        padding: 50px;
        border-radius: 28px;
      }

      .description {
        margin: 0 0 35px 0;

        h2,
        h3 {
          font-family: ${subFont};
          font-size: 1.55rem;
          margin: 0 0 20px 0;
          @media ${screen.xsmall} {
            font-size: 1.8rem;
          }
          @media ${screen.small} {
            font-size: 1.9rem;
          }
          @media ${screen.medium} {
            font-size: 2rem;
          }

          strong {
            color: ${mainPurple};
          }
        }

        p {
          font-size: 1.05rem;
          margin: 0 0 21px 0;
          font-weight: 500;
          @media ${screen.xsmall} {
            font-size: 1.15rem;
          }
          @media ${screen.small} {
            font-size: 1.2rem;
          }
          @media ${screen.medium} {
            font-size: 1.25rem;
          }
          &:last-child {
            margin: 0;
          }
        }
      }

      .btn {
        a {
          all: unset;
          background: ${darkPurple};
          color: ${mainWhite};
          font-weight: 600;
          font-size: 1.05rem;
          padding: 8px 24px;
          line-height: 1.5;
          border-radius: 10px;
          cursor: pointer;
          transition: ${transHover};
          @media ${screen.xsmall} {
            font-size: 1.1rem;
            padding: 9px 26px;
          }
          @media ${screen.small} {
            font-size: 1.16rem;
          }
          @media ${screen.medium} {
            font-size: 1.23rem;
            padding: 10px 30px;
          }

          @media ${screen.withCursor} {
            &:hover {
              background: ${mainPurple};
            }
          }
        }
      }
    }
  }
`;

// return boolean to use for classname css styling
const isBio = (num) => {
  return num > 8;
};

const AboutPage = ({ data }) => {
  const {
    banner_background_image,
    banner_description,
    intro_description,
    intro_featured_image,
    team_description,
    team_members,
    testimonials_description,
    slides,
    aside_list,
    title_tag,
    meta_description,
    join_description,
    join_background_image,
  } = data.main.data;

  const { contact_email } = data.contact.data;

  const [activeMember, setActiveMember] = useState({});

  return (
    <Layout>
      <Seo
        title={title_tag}
        description={meta_description}
        image={intro_featured_image.url}
      />
      <Wrapper
        banner_background_image={banner_background_image.url}
        join_background_image={join_background_image.url}
      >
        <header className="hero">
          <div className="gradient-bg" role="presentation"></div>
          <div className="text-wrapper">
            <div
              className="text-subwrapper"
              data-sal="slide-right"
              data-sal-duration="500"
            >
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: banner_description.html }}
              />
            </div>
          </div>

          <div className="arrows-bg" role="presentation">
            <StaticImage
              src="../images/arrows_home-about-banner-arrows.png"
              alt="Graphics"
              placeholder="blurred"
            />
          </div>
        </header>

        <section className="intro">
          <div className="inner-wrapper inner-wrapper--intro">
            <div
              className="col col--left"
              data-sal="slide-up"
              data-sal-duration="500"
            >
              <GatsbyImage
                image={intro_featured_image.thumbnails.cropped.gatsbyImageData}
                alt={intro_featured_image.alt || "Featured"}
              />
            </div>
            <div
              className="col col--right"
              data-sal="slide-up"
              data-sal-duration="500"
            >
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: intro_description.html }}
              />
            </div>
          </div>
        </section>

        <section className="team">
          <div className="inner-wrapper">
            <div
              className="description"
              data-sal="slide-up"
              data-sal-duration="500"
              dangerouslySetInnerHTML={{ __html: team_description.html }}
            />
            <ul className="team-list">
              {team_members.map((item) => (
                <li
                  className={
                    isBio(item.description.html.length)
                      ? "team-list__each"
                      : "team-list__each team-list__each--no-bio"
                  }
                  data-sal="slide-up"
                  data-sal-duration="500"
                  key={item.name}
                  onClick={() => {
                    setActiveMember({
                      name: item.name,
                      image: item.image.thumbnails.cropped.gatsbyImageData,
                      position: item.position,
                      description: item.description.html,
                      linkedInUrl: item.linkedin,
                      facebookUrl: item.facebook,
                    });
                  }}
                >
                  <figure className="img">
                    <GatsbyImage
                      image={item.image.thumbnails.cropped.gatsbyImageData}
                      alt={item.image.alt || "Portrait"}
                    />
                    <div className="overlay">
                      <p>
                        <span>Read more</span>{" "}
                        <span>
                          <BrandArrow />
                        </span>
                      </p>
                    </div>
                  </figure>
                  <h3 className="name">{item.name}</h3>
                  <p className="position">{item.position}</p>
                </li>
              ))}
            </ul>
          </div>

          <div className="arrows-bg" role="presentation">
            <StaticImage
              src="../images/Tableau-WhatIsEmbedded-Arrows.png"
              alt="Graphic"
              placeholder="blurred"
            />
          </div>
        </section>

        <section className="testimonials">
          <div className="inner-wrapper inner-wrapper--testimonials">
            <div
              className="col col--left"
              data-sal="slide-up"
              data-sal-duration="500"
            >
              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html: testimonials_description.html,
                }}
              />

              <CarouselTestimonials className="slides" items={slides} />
            </div>
            <aside
              className="col col--right"
              data-sal="slide-up"
              data-sal-duration="500"
            >
              <ul className="aside-list">
                {aside_list.map((item, i) => (
                  <li className="aside-list__each" key={i}>
                    <div
                      className="description"
                      dangerouslySetInnerHTML={{
                        __html: item.description.html,
                      }}
                    />
                  </li>
                ))}
              </ul>
            </aside>
          </div>
        </section>

        <section className="join">
          <div className="inner-wrapper">
            <div className="box" data-sal="slide-up" data-sal-duration="500">
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: join_description.html }}
              />

              <div className="btn">
                <Obfuscate email={contact_email}>EMAIL US</Obfuscate>
              </div>
            </div>
          </div>
        </section>
      </Wrapper>
      {activeMember.name && (
        <LightboxTeamMember
          member={activeMember}
          setActiveMember={setActiveMember}
        />
      )}
    </Layout>
  );
};

export default AboutPage;

export const dataQuery = graphql`
  {
    main: prismicAboutPage {
      data {
        title_tag
        meta_description
        banner_description {
          html
        }
        banner_background_image {
          url
        }
        intro_description {
          html
        }
        intro_featured_image {
          alt
          url
          thumbnails {
            cropped {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        team_description {
          html
        }
        team_members {
          image {
            alt
            thumbnails {
              cropped {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          name
          position
          description {
            html
          }
          linkedin
          facebook
        }
        testimonials_description {
          html
        }
        aside_list {
          description {
            html
          }
        }
        slides {
          description {
            html
          }
        }
        join_description {
          html
        }
        join_background_image {
          url
        }
      }
    }
    contact: prismicGeneralDetails {
      data {
        contact_email
      }
    }
  }
`;
